.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@media (max-width: 600px) {
  div {
    flex-direction: column;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Poppins', sans-serif; /* Specific font for paragraph elements */
}

h1 {
  text-align: center;
  color: #333;
  font-family: 'Poppins', sans-serif; /* Specific font for paragraph elements */
}


h1alt {
  font-size: 24pt;
  color: lightgray;
  font-weight: bold;
  text-align: center;
}

h1rev {
  font-size: 24pt;
  text-align: center;
  color: #e2dddd;
  font-family: 'Poppins', sans-serif; /* Specific font for paragraph elements */
}

/* Styles for file input and selected file name */
input[type="file"] {
  display: none;
}
p.file-name {
  margin-top: 10px;
}

/* Styles for select dropdown */
label {
  display: block;
  margin-top: 10px;
}
select {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

/* Styles for encryption section */
.encryption-section {
  margin-top: 20px;
}

/* Styles for encryption key input */
input[type="text"] {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

/* Styles for dropzone */
.dropzone {
  outline: 2px dashed khaki;
  background: khaki;
  padding: 20px;
  text-align: center;
}

/* Styles for encrypted data textarea */
textarea {
  width: 100%;
  height: 150px;
  margin-top: 10px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Styles for decrypt section */
.decrypt-section {
  margin-top: 20px;
}

/* Styles for download button */
button {
  margin-top: 10px;
  padding: 8px 16px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* CSS for full-width responsive image */
.full-width-image {
  width: 100%;
  height: auto;
  display: block;
}


@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');